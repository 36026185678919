import React from 'react';
import { Box, Divider, IconButton, Typography, List, ListItem, ListItemAvatar, Avatar, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../common/hooks/useAuth';
import { SideMenuItemChild } from './SideMenuChild';
import { SideMenuItem } from './SideMenuItem';
import { LOCATIONS } from '../locations/locations';

import { growAll , GrowthStages , teleportAll , healAll , marksAll , prepAll } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBolt, faCogs, faFolderMinus, faHamburger, faSeedling } from '@fortawesome/free-solid-svg-icons';

export const SideNav = () => {
  const { user, authenticated } = useAuth();
  const { avatar, avatarAccent, displayName, agid } = user || { }
  const [visible, setVisible] = React.useState(false)

  const onClose = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const onOpen = React.useCallback(() => {
    setVisible(true)
  }, [setVisible])
  
    const onPrep = React.useCallback(() => {
      onClose()
        prepAll()
    }, [])
    
    const onTeleport = React.useCallback((location: string) => {
      onClose()
        teleportAll(location)
    }, [])
    
    const onGrowAdult = React.useCallback(() => {
      onClose()
        growAll(GrowthStages.ADULT)
    }, [])
    
    const onGrowSub= React.useCallback(() => {
      onClose()
      growAll(GrowthStages.SUB_ADULT)
    }, [])
    
    const onGrowAdo = React.useCallback(() => {
      onClose()
      growAll(GrowthStages.ADOLECENT)
    }, [])
    
    const onGrowJuvi = React.useCallback(() => {
      onClose()
      growAll(GrowthStages.JUVINILE)
    }, [])
    
    const onGrowHatchling = React.useCallback(() => {
      onClose()
      growAll(GrowthStages.HATCHLING)
    }, [])
    
    const onMarks = React.useCallback(() => {
      onClose()
        marksAll('500000')
    }, [])
    
    const onHeal = React.useCallback(() => {
      onClose()
        healAll()
    }, [])

    const sideNavRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (sideNavRef.current && !sideNavRef.current.contains(event.target as Node)) {
          onClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);

    if (!authenticated || !user?.admin) {
      return null;
    }

    if (!visible) {
        return (
            <Button startIcon={<FontAwesomeIcon icon={faBars} />} onClick={onOpen} />
        );
    }

    return (
        <Box ref={sideNavRef} sx={{ width: 240, height: '100%', boxShadow: 3, bgcolor: 'white', position: 'absolute', zIndex: 50 }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
              </Box>
              <ListItem>
                  <ListItemAvatar>
                      <Avatar src={avatar} sx={{ bgcolor: avatarAccent, border: '2px solid grey.600' }} />
                  </ListItemAvatar>
                  <Box sx={{ ml: 2 }}>
                      <Typography variant="body2" fontWeight="bold" color="blue.600">{displayName}</Typography>
                      <Typography variant="body2" fontWeight="bold" color="blue.600">{agid}</Typography>
                  </Box>
              </ListItem>
              <Box sx={{ ml: 3, mt: 1 }}>
                  <Typography variant="h6" fontWeight="bold">Global Commands</Typography>
              </Box>
              <List>
              <SideMenuItem title="Basic" icon={<FontAwesomeIcon icon={faCogs} />}>
                  <SideMenuItemChild onClick={onPrep}>All</SideMenuItemChild>
                  <SideMenuItemChild onClick={onGrowAdult}>Grow</SideMenuItemChild>
                  <SideMenuItemChild onClick={onMarks}>Marks</SideMenuItemChild>
                  <SideMenuItemChild onClick={onHeal}>Heal</SideMenuItemChild>
              </SideMenuItem>
              <SideMenuItem title="Grow" icon={<FontAwesomeIcon icon={faSeedling} />}>
                  <SideMenuItemChild onClick={onGrowAdult}>Adult</SideMenuItemChild>
                  <SideMenuItemChild onClick={onGrowSub}>Sub Adult</SideMenuItemChild>
                  <SideMenuItemChild onClick={onGrowAdo}>Ado</SideMenuItemChild>
                  <SideMenuItemChild onClick={onGrowJuvi}>Juvi</SideMenuItemChild>
                  <SideMenuItemChild onClick={onGrowHatchling}>Hatchling</SideMenuItemChild>
              </SideMenuItem>
              <SideMenuItem title="Teleport" icon={<FontAwesomeIcon icon={faBolt} />}>
                {
                  LOCATIONS.map(({ name, location }) => {
                    return <SideMenuItemChild key={location} item={location} onClick={onTeleport}>{name}</SideMenuItemChild>
                  })
                }
              </SideMenuItem>
              </List>
          </Box>
        </Box>
    );
};
