export enum GrowthStages {
    ADULT = '1',
    SUB_ADULT = '.75',
    JUVINILE = '.25',
    ADOLECENT = '.5',
    HATCHLING = '0',
  }
  
  export interface List {
    count: number;
    players: Player[];
  }
  
  export interface CommandResponse {
    command: string;
    response: string;
  }
  
  export interface Player {
    name: string;
    agid: string;
  }
  
  export interface PlayerDetails extends Player {
    dinosaur: string;
    role: string;
    marks: string;
    growth: string;
  }

  
export const GROWTH_STAGE_FRIENDLY: { [key: string]: string } = {
  [GrowthStages.ADULT]: 'Adult',
  [GrowthStages.SUB_ADULT]: 'Sub Adult',
  [GrowthStages.ADOLECENT]: 'Adolecent',
  [GrowthStages.JUVINILE]: 'Juvinile',
  [GrowthStages.HATCHLING]: 'Hatchling',
}

export enum BasicActions {
  PREP = 'prep',
  GROW = 'grow',
  HEAL = 'heal',
  MARK = 'mark',
}

export enum HackActions {
  MAX_HEALTH = 'max-health',
  MAX_STAMINA = 'max-stamina',
  MAX_MOVEMENT = 'max-movement',
  MAX_SPRINTING = 'max-sprinting',
  MAX_TROTTING = 'max-trotting',
  MAX_COMBAT = 'max-combat',
  SKIP_SHED = 'skip-shed',
  KILL = 'kill',
}


export const BASIC_ACTIONS_FRIENDLY: { [key: string]: string } = {
  [BasicActions.PREP]: 'ALL',
  [BasicActions.GROW]: 'Grow Adult',
  [BasicActions.MARK]: 'Marks 500k',
  [BasicActions.HEAL]: 'Heal 100%',
}

export const HACK_ACTIONS_FRIENDLY: { [key: string]: string } = {
  [HackActions.MAX_HEALTH]: 'Max Health',
  [HackActions.MAX_STAMINA]: 'Max Stamina',
  [HackActions.MAX_MOVEMENT]: 'Max Movement',
  [HackActions.MAX_SPRINTING]: 'Max Sprinting',
  [HackActions.MAX_TROTTING]: 'Max Trotting',
  [HackActions.MAX_COMBAT]: 'Max Combat',
  [HackActions.SKIP_SHED]: 'Skip Shed',
  [HackActions.KILL]: 'Kill',
}

export const basicDropdownItems = Object.keys(BASIC_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: BASIC_ACTIONS_FRIENDLY[action],
      value: action,
      item: action as BasicActions
  }
});

export const hackDropdownItems = Object.keys(HACK_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: HACK_ACTIONS_FRIENDLY[action],
      value: action,
      item: action as HackActions
  }
});

export const growthDropdownItems = Object.keys(GROWTH_STAGE_FRIENDLY).map((stage: string) => {
  return {
      label: GROWTH_STAGE_FRIENDLY[stage],
      value: stage,
      item: stage as GrowthStages
  }
}).sort((a, b) => (Number(a.item) > Number(b.item) ? -1 : 1));