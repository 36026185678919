import { DispatchFunction, GetStateFunction } from "../reducers";
import { getServerSettings, updateRespawnGrowth as apiUpdateRespawnGrowth, updateRespawnLocation as apiUpdateRespawnLocation, loadServerLocations } from "../api/settings";
import { LOCATIONS_RECEIVED, SETTINGS_UPDATE } from "./types";


export const loadServerSettings = () => async (dispatch: DispatchFunction) => {
    const settings = await getServerSettings();
    dispatch({ type: SETTINGS_UPDATE, payload: settings });
}

export const updateRespawnLocation = (location: string) => async (dispatch: DispatchFunction) => {
    await apiUpdateRespawnLocation(location);
    
    dispatch({ type: SETTINGS_UPDATE, payload: { respawn_point: location } });
}

export const updateRespawnGrowth = (growth: string) => async (dispatch: DispatchFunction) => {
    await apiUpdateRespawnGrowth(growth);
    
    dispatch({ type: SETTINGS_UPDATE, payload: { respawn_growth: growth } });
}

export const loadLocations = () => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { settings } = getState();
    if (settings.locations.length > 0) {
        return;
    }

    const locations = await loadServerLocations();
    const locationOptions = locations.map((location) => {
      return {
        label: location,
        value: location,
      };
    });
    dispatch({ type: LOCATIONS_RECEIVED, payload: locationOptions });
}