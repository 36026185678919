import React, { FunctionComponent, ReactElement, useEffect } from "react";

import { useAuth } from "../../common/hooks/useAuth";
import { useNavigate } from "react-router";

export const RequiresAuthentication: FunctionComponent<{ children: ReactElement<any, any> }> = ({ children }) => {
    const { loading, initialized, authenticated } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (loading || !initialized) {
            return
        }

        if (!authenticated) {
            navigate("/login");
        }
    }, [loading, initialized, authenticated])
    

    useEffect(() => {
        if(loading || !initialized || !authenticated) {
            return;
        }
    }, [loading, initialized, authenticated])
    if(loading || !initialized) {
        return null;
    }

    if (!authenticated) {
        return null;
    }
  
    return <>{children}</>
}