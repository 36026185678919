import React, { FunctionComponent } from "react";
import { Box, Button, Menu, MenuItem } from '@mui/material';

export interface DropdownItem<T = any> {
    label: string;
    value: string;
    item?: T;
}

interface DropdownButtonProps<T = any> {
    items: DropdownItem<T>[];
    label: string;
    onItemSelected: (item: DropdownItem<T>) => any;
}
interface DropdownButtonMenuItemProps<T = any> {
    item: DropdownItem<T>;
    onClick: (item: DropdownItem<T>) => any;
}

export const DropdownButtonMenuItem: FunctionComponent<DropdownButtonMenuItemProps> = ({ item, onClick: propsOnItemSelected }) => {
    const onClick = React.useCallback(() => {
        propsOnItemSelected(item);
    }, [item, propsOnItemSelected]);

    return <MenuItem sx={{ minWidth: '10rem'}} onClick={onClick}>{item.label}</MenuItem>
}


export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({ items, label, onItemSelected: propsOnItemSelected }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemSelected = React.useCallback((item: DropdownItem) => {
        propsOnItemSelected(item);
        handleClose()
    }, [propsOnItemSelected]);

    return (
        <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Button onClick={handleClick}>{label}</Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {items.map((item: DropdownItem) => {
                    return <DropdownButtonMenuItem item={item} onClick={onItemSelected} />
                })}
            </Menu>
        </Box>
    );
}
