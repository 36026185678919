import { User } from '../context';
import { useAuth } from './useAuth';

export interface withAuthProps {
  authenticated: boolean
  authLoading: boolean
  authInitialized: boolean
  user?: User
}

export const withAuth = (Component: any) => {
  const Wrapper = (props: any) => {
    const { authenticated, user, loading, initialized } = useAuth();
    return (
      <Component
        authenticated={authenticated}
        authLoading={loading}
        authInitialized={initialized}
        user={user}
        {...props}
        />
    );
  };
  
  return Wrapper;
};