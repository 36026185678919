import axios from 'axios'

import { AlderonUser, LoginError, LoginResponse } from './auth.types'

const AUTH_ENDPOINT = `/login`
const USER_ENDPOINT = `/user`

export const authenticate = async(username: string, password: string): Promise<LoginResponse> => {
    const payload = {
        username,
        password,
    }
    try {
        const { data } = await axios.post(AUTH_ENDPOINT, payload)

        return data as LoginResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new LoginError(e.response.data)
        }

        throw e
    }
}

export const userDetails = async(accessToken: string): Promise<AlderonUser> => {
    const { data } = await axios.get(USER_ENDPOINT, { headers: { 'x-access-token': accessToken }})

    return data as AlderonUser
}