import React, { FunctionComponent } from 'react';
import { Box, List, ListItem, ListItemButton, Collapse, SvgIcon } from '@mui/material';

interface SideMenuItemProps {
    title: string;
    icon?: React.ReactNode;
    children: React.ReactNode;
}

export const SideMenuItem: FunctionComponent<SideMenuItemProps> = ({ title, icon, children }) => {
    const [childrenVisible, setChildrenVisible] = React.useState<boolean>(false);
    const onToggleChildren = React.useCallback(() => {
        setChildrenVisible(!childrenVisible);
    }, [childrenVisible]);

    return (
        <ListItem disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
            <ListItemButton onClick={onToggleChildren} sx={{ display: 'flex', width: '100%', height: 48, justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {icon}
                    <span>{title}</span>
                </Box>
                <SvgIcon>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                </SvgIcon>
            </ListItemButton>
            <Collapse in={childrenVisible} timeout="auto" unmountOnExit sx={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <List component="div" disablePadding >
                    {children}
                </List>
            </Collapse>
        </ListItem>
    );
};
