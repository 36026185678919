import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export interface ConfirmationDialogRawProps {
  id: string;
  title: string,
  okButtonText?: string,
  cancelButtonText?: string,
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogRawProps) => {
  const { onClose, onConfirmed, visible, children, title, okButtonText, cancelButtonText, ...other } = props;



  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={visible}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {cancelButtonText || 'Cancel'}
        </Button>
        <Button onClick={onConfirmed}>{okButtonText || 'OK'}</Button>
      </DialogActions>
    </Dialog>
  );
}