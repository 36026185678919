import { LOCATIONS_RECEIVED, SETTINGS_UPDATE } from "../actions/types";
import { SelectOption } from "../components/common/Select";

export interface SettingsState {
    respawn_point?: string;
    respawn_growth?: string;
    locations: SelectOption<string>[];
}

export const SettingsInitialState: SettingsState = {
    locations: [],
};

export const SettingsReducer = (state = SettingsInitialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case LOCATIONS_RECEIVED:
            return {
                ...state,
                locations: payload ?? [],
            };
        case SETTINGS_UPDATE:
            return {
                ...state,
                respawn_point: payload.respawn_point ?? state.respawn_point,
                respawn_growth: payload.respawn_growth ?? state.respawn_growth,
            };
    }
    return state;
}