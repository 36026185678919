import { useCallback } from 'react';
import { User } from '../context';
import { useTheme, useMediaQuery, Theme, Breakpoint, UseMediaQueryOptions } from '@mui/material';


export interface WithSmallScreenCheckProps {
  isSmallScreen: boolean
}

export const withSmallScreenCheck = (Component: any) => {
  const Wrapper = (props: any) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <Component
        theme={theme}
        isSmallScreen={isSmallScreen}
        {...props}
        />
    );
  };
  
  return Wrapper;
};