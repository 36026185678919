/*eslint import/namespace: [2, { allowComputed: true }]*/
import React from 'react';
import axios from 'axios'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './components/home';
import { LoginComponent } from './components/login/Login';
import { Header } from './components/nav/Header';
import { Box } from '@mui/material';
import { AuthProvider } from './common/providers/AuthProvider';
import { CommandPage } from './components/command';
import { SettingsPage } from './components/settings';
import { RequiresAuthentication } from './components/common/RequiresAuthentication';
import UserSync from './components/misc/UserSync';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const App: React.FunctionComponent = () => {
  return (
      <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <Header />
              <UserSync />
              <Box>
                <Routes>
                  <Route path="/login" element={<LoginComponent />} />
                  <Route path="/" element={<RequiresAuthentication><Home /></RequiresAuthentication>} />
                  <Route path="/command" element={<RequiresAuthentication><CommandPage /></RequiresAuthentication>} />
                  <Route path="/settings" element={<RequiresAuthentication><SettingsPage /></RequiresAuthentication>} />
                </Routes>
              </Box>
            </Box>
      </Router>
  );
};
