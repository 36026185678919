import { Component } from "react";
import { Box, Typography } from '@mui/material';
import { PlayerDetails } from "../../api";
import { PlayerCard } from "./PlayerCard";

interface PlayerRowProps {
    players: PlayerDetails[];
}

export class PlayerCards extends Component<PlayerRowProps> {

    render() {
        const {players} = this.props;

        return (
            <Box>
                {players.length === 0 &&  <Typography variant="h6">No active players on the server</Typography>}
                {
                    players.map((player) => {
                        return <PlayerCard key={player.agid} player={player} />
                    })
                }
            </Box>
        );
    }
}
