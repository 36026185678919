import axios from 'axios';
import { CreatorModeItem, CreatorModeOption } from './creator-modes.types';


const CREATOR_MODE_ENDPOINT = '/creator-modes'

export const getCreatorModeItems = async(): Promise<CreatorModeItem[]> => {
    const { data } = await axios.get(CREATOR_MODE_ENDPOINT);
    const { items } = data;

    return items;
}

export const getCreatorModeItemOptions = async(): Promise<CreatorModeOption[]> => {
    const items = await getCreatorModeItems();

    return items.map((item) => ({
        label: item.name,
        value: item.id,
        item,
    }));
}

export const resetCreatorMode = async(): Promise<void> => {
    try {
        await axios.post(`${CREATOR_MODE_ENDPOINT}/reset`);
    } catch (e) {
        console.error(e);
    }
}

export const loadCreatorMode = async(item: CreatorModeItem): Promise<void> => {
    try {
        await axios.post(`${CREATOR_MODE_ENDPOINT}/${item.id}/load`);
    } catch (e) {
        console.error(e);
    }
}