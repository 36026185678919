import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTicketAlt, faMap, faList, faCopy, faHandsHelping, faChartLine, faNewspaper, faRefresh } from '@fortawesome/free-solid-svg-icons'


import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { listPlayers, PlayerDetails } from '../../api';
import { Page } from '../common/Page';
import {PlayersTable} from '../players/PlayersTable';
// import { CustomCommand } from './CustomCommand';
import { WithMediaQueryProps, withMediaQuery } from '../../common/hooks/withMedia';
import { WithSmallScreenCheckProps, withSmallScreenCheck } from '../../common/hooks/withSmallScreenCheck';
import { CustomCommand } from './CustomCommand';
import { PlayerCards } from '../players/PlayerCards';

const POLL_INTERVAL_MS = 1000 * 5;

interface CommandProps extends withAuthProps {}

interface CommandState {
}

class CommandComponent extends React.Component<CommandProps, CommandState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      players: [],
    }
  }

  render = () => {
    const { user } = this.props;


    return (
        <Page>
            <Box sx={{ p: 5 }}>
                {user?.admin && <CustomCommand />}
            </Box>
        </Page>
    );
  }
}


export const CommandPage = withAuth(withSmallScreenCheck(withAuth(CommandComponent)))