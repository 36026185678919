import axios from 'axios';
import React, { FunctionComponent } from 'react';

import { LoginResponse } from '../../api/auth.types';
import { authenticate, userDetails } from '../../api/auth';
import { AuthContext, AuthContextProps, AuthenticationParams, User } from "../context";

const AUTH_TOKEN = 'AUTH_TOKEN'
const USER = 'USER'

const initialState: AuthContextProps = {
  authenticated: false,
  loading: true,
  initialized: false,
  setAuthentication: (authenticationParams: AuthenticationParams) => { },
  login: (email, password) => { },
  logout: () => {},
};

export interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: FunctionComponent<
AuthProviderProps
> = ({ children }): JSX.Element => {
  const [props, setProps] = React.useState<AuthContextProps>(initialState)
  const [accessToken, setToken] = React.useState<string | null | undefined>()
  const [user, setUser] = React.useState<User | null |  undefined>()
  const [initialized, setInitialized] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setUser(localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER) as string) : null)
    setToken(localStorage.getItem(AUTH_TOKEN))
    setInitialized(true)
    setLoading(false)
  }, [])

  const logout = React.useCallback(() => {
    localStorage.removeItem(USER)
    localStorage.removeItem(AUTH_TOKEN);
    window.location.reload()
  }, [])

  React.useEffect(() => {
    (axios.defaults.headers as any)['x-access-token'] = accessToken;
    if (accessToken) {
      axios.interceptors.response.use(
        response => response,
        error => {
          const data = error.response?.data?.data ?? error.response.data ?? {};
          const message = data?.message ?? ''
          if (message.includes('Unauthenticated')) {
            logout()
          }
          return Promise.reject(error);
      }
    );
    }
  }, [accessToken])

  const login = React.useCallback(async(email: string, password: string): Promise<LoginResponse> => {
    setLoading(true)
    try {
      const response = await authenticate(email, password)
      const { access_token: token } = response
      if (!token) {
        throw new Error('Token not retrieved')
      }
  
      setToken(token)
      localStorage.setItem(AUTH_TOKEN, token);
      const { id, avatar: { image, accent }, display_name: displayName, admin } = await userDetails(token as string);
      const user = { email, id: id.identifier, agid: id.display, avatar: image, avatarAccent: accent, displayName, admin }
  
      localStorage.setItem(USER, JSON.stringify(user))
      setUser(user)
      setLoading(false)

      return response
    } catch (e) {
      setLoading(false)
      logout()
      throw e
    }
  }, [])

  React.useEffect(() => {
    setProps({
      authenticated: !!accessToken,
      user: user || undefined,
      accessToken: accessToken || undefined,
      loading,
      initialized,
      setAuthentication: ({ user, accessToken }) => {
        setToken(accessToken)
        setUser(user)
      },
      logout,
      login,
    })
  }, [accessToken, user, login, logout, loading])

  return (
    <AuthContext.Provider value={props}>
      {children}
    </AuthContext.Provider>
  );
};
