import React, { FunctionComponent } from "react";
import { Box, Card, CardContent, CardActions, Typography } from '@mui/material';
import { basicAction, BasicActions, basicDropdownItems, grow, GROWTH_STAGE_FRIENDLY, growthDropdownItems, GrowthStages, hackAction, HackActions, hackDropdownItems, PlayerDetails, teleport } from "../../api";
import { Location, teleportDropdownItems } from "../locations/locations";
import { DropdownButton, DropdownItem } from "../common/DropdownButton";
import { useAuth } from "../../common/hooks/useAuth";

interface PlayerRowProps {
    player: PlayerDetails;
}

export const PlayerActions: FunctionComponent<PlayerRowProps> = ({ player }) => {
    const { user } = useAuth();
    const { name } = player
    const canUseActions = React.useMemo(() => {
        return user?.admin || user?.agid === player.agid;
    }, [user, player.agid]);
    const onGrowthItemSelected = React.useCallback((item: DropdownItem<GrowthStages>) => {
        const { item: growthStage } = item;
        if (growthStage) {
            grow(name, growthStage);
        }
    }, [name]);

    const onTeleportItemSelected = React.useCallback((item: DropdownItem<Location>) => {
        const { item: location } = item;
        if (location) {
            teleport(name, location.location);
        }
    }, [name]);
    
    const onBasicAtionItemSelected = React.useCallback((item: DropdownItem<BasicActions>) => {
        const { item: action } = item;
        if (action) {
            basicAction(name, action);
        }
    }, [name]);

    const onHackItemSelected = React.useCallback((item: DropdownItem<HackActions>) => {
        const { item: action } = item;
        if (action) {
            hackAction(name, action);
        }
    }, [name]);
    
    const userBasicDropdownItems = React.useMemo(() => {
        return basicDropdownItems.filter((item) => {
            if ((item.item === BasicActions.HEAL || item.item === BasicActions.PREP) && !user?.admin) {
                return false;
            }

            return true;
        });
    }, [user]);

    if (!canUseActions) {
        return null;
    }
    return (
        <CardActions>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <DropdownButton label={'Basic'} items={userBasicDropdownItems} onItemSelected={onBasicAtionItemSelected} />
                <DropdownButton label={'Growth'} items={growthDropdownItems} onItemSelected={onGrowthItemSelected} />
                <DropdownButton label={'Teleport'} items={teleportDropdownItems} onItemSelected={onTeleportItemSelected} />
                <DropdownButton label={'Hacks'} items={hackDropdownItems} onItemSelected={onHackItemSelected} />
            </Box>
        </CardActions>
    );
}
