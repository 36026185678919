/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';


import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { PlayerDetails } from '../../api';
import { PlayerRow } from './PlayerRow';
import { WithSmallMediaCheckProps, withSmallMediaCheck } from '../../common/hooks/withSmallMediaCheck';

interface PlayersTableProps extends withAuthProps, WithSmallMediaCheckProps {
  players: PlayerDetails[]
}


class PlayersTableComponent extends React.Component<PlayersTableProps> {

  render = () => {
    const { players } = this.props;
    const hideAGID = this.props.smallerThan.lg;
    return (
      <TableContainer>
          <Table>
              <TableHead>
                  <TableRow>
                      <TableCell>Name</TableCell>
                      {!hideAGID && <TableCell>AGID</TableCell>}
                      <TableCell>Dinosaur</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Marks</TableCell>
                      <TableCell>Growth</TableCell>
                      <TableCell>Actions</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {players.length === 0 &&
                      <TableRow>
                          <TableCell colSpan={hideAGID ? 6 : 7}>No active players on the server</TableCell>
                      </TableRow>
                  }
                  {players.map((player) => {
                      return <PlayerRow hideAGID={hideAGID} key={player.agid} player={player} />;
                  })}
              </TableBody>
          </Table>
      </TableContainer>
    );
  }
}


export const PlayersTable = withSmallMediaCheck(withAuth(PlayersTableComponent))