import axios from 'axios'

import { BasicActions, CommandResponse, GrowthStages, HackActions, PlayerDetails } from './api.types';

const MOCK_DATA = [
    {
        "name": "MidOneMadCow",
        "agid": "864-531-056",
        "dinosaur": "Invalid Character Data Asset",
        "role": "None",
        "marks": "0",
        "growth": "1"
    },
    {
        "name": "OliviaKittyCorn",
        "agid": "650-178-349",
        "dinosaur": "Ceratosaurus",
        "role": "None",
        "marks": "25600",
        "growth": "1"
    },
    {
        "name": "WingedRaptorFPV",
        "agid": "777-859-022",
        "dinosaur": "Anodontosaurus",
        "role": "None",
        "marks": "375000",
        "growth": "1"
    },
    {
        "name": "MrsSharptooth",
        "agid": "074-052-294",
        "dinosaur": "Anodontosaurus",
        "role": "None",
        "marks": "375000",
        "growth": "1"
    },
];

const PLAYERS_ENDPOINT = '/players'

export const listPlayers = async(): Promise<PlayerDetails[]> => {
    const { data } = await axios.get(PLAYERS_ENDPOINT);

    return data;
}

export const growAll = async(stage: GrowthStages): Promise<PlayerDetails> => {
    const { data } = await axios.post(`/grow`, { stage });

    return data;
}

export const healAll = async(): Promise<PlayerDetails> => {
    const { data } = await axios.post(`/heal`);

    return data;
}

export const marksAll = async(amount: string): Promise<PlayerDetails> => {
    const { data } = await axios.post(`/marks`, { amount });

    return data;
}

export const teleportAll = async(location: string): Promise<PlayerDetails> => {
    const { data } = await axios.post(`/teleport`, { location });

    return data;
}

export const prepAll = async(): Promise<PlayerDetails> => {
    const { data } = await axios.post(`/prep`);

    return data;
}

export const details = async(playerName: string): Promise<PlayerDetails> => {
    const { data } = await axios.get(`${PLAYERS_ENDPOINT}/${playerName}`);

    return data;
}

export const heal = async(playerName: string): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/heal`);

    return data;
}

export const grow = async(playerName: string, stage: GrowthStages): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/grow`, { stage });

    return data;
}

export const marks = async(playerName: string, amount: string): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/marks`, { amount });

    return data;
}


export const hack = async(playerName: string, action: HackActions): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/${action}`);

    return data;
}

export const stamina = async(playerName: string, amount: string): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/stamina`, { amount });

    return data;
}

export const teleport = async(playerName: string, location: string): Promise<CommandResponse> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/teleport`, { location });

    return data;
}

export const prep = async(playerName: string): Promise<void> => {
    const { data } = await axios.post(`${PLAYERS_ENDPOINT}/${playerName}/prep`);

    return data;
}

export const sendCommand = async(command: string): Promise<CommandResponse> => {
    const { data } = await axios.post(`/send`, { command });

    return data;
}

export const basicAction = async(playerName: string, action: BasicActions): Promise<void> => {
    if (action === BasicActions.PREP) {
        await prep(playerName);
    }
    if (action === BasicActions.HEAL) {
        await heal(playerName);
    }
    if (action === BasicActions.GROW) {
        await grow(playerName, GrowthStages.ADULT);
    }
    if (action === BasicActions.MARK) {
        await marks(playerName, '500000');
    }
}

export const hackAction = async(playerName: string, action: HackActions): Promise<void> => {
    await hack(playerName, action);
}

export const basicActionAll = async(action: BasicActions): Promise<void> => {
    if (action === BasicActions.PREP) {
        await prepAll();
    }
    if (action === BasicActions.HEAL) {
        await healAll();
    }
    if (action === BasicActions.GROW) {
        await growAll(GrowthStages.ADULT);
    }
    if (action === BasicActions.MARK) {
        await marksAll('500000');
    }
}
