export interface AlderonIdentifier {
    identifier: number;
    display: string;
    platform?: string | null;
  }
  
  export interface AlderonAvatar {
    image: string;
    accent: string;
  }
  
export interface AlderonUser {
    id: AlderonIdentifier;
    avatar: AlderonAvatar;
    admin: boolean;
    verified: boolean;
    display_name: string;
    email: string;
    attributes: any[];
    currency: {
      void: number;
      earned_pot: number;
      paid_pot: number;
    };
    tokens: {
      short: string;
      provider: string;
      report: string;
      login: string;
      analytics: string;
      deletion: string;
    };
    eos: {
      product_user_id: string;
      organization_user_id: string;
    };
    config: any[];
    crossplay: boolean;
    crosscommerce: boolean;
  }

export interface LoginResponse {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
  }

  export interface LoginErrorResponse {
    error: string;
    error_description: string;
    message: string;
  }

  export class LoginError extends Error {
    error: string
    error_description: string
    message: string

    constructor({ error, error_description, message }: LoginErrorResponse) {
        super(message)
        this.error = error;
        this.error_description = error_description;
        this.message = message;
    }
  }