import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { reducers } from '../reducers';

let middleware: any = [thunk]
if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger();
    middleware = [...middleware, logger]
}

export default createStore(
    reducers,
    applyMiddleware(...middleware)
);