export interface Location {
    name: string
    location: string
}

export const LOCATIONS: Location[] = [
    {
        name: 'LBM Center',
        location: '(X=154961.773359,Y=-276987.543385,Z=-7900.128578)',
    },
    {
        name: 'LBM Tree',
        location: '(X=146572.54716,Y=-283663.632322,Z=-8007.272368)',
    },
    {
        name: 'BVB',
        location: '(X=334392.005149,Y=-253645.581583,Z=-3621.30999)'
    },
    {
        name: 'NE WS',
        location: '(X=286328.398921,Y=-281355.216016,Z=2353.436924)',
    },
    {
        name: 'SW Battle Cave',
        location: '(X=-335934.852827,Y=219779.424037,Z=16742.344769)'
    },
]

export const teleportDropdownItems = LOCATIONS.map((item) => {
  return {
      label: item.name,
      value: item.location,
      item: item as Location
  }
});
