import React from 'react'
import { useAuth } from './useAuth';

export const withLogin = (Component: any) => {
  const Wrapper = (props: any) => {
    const { login } = useAuth();
    return (
      <Component
        login={login}
        {...props}
        />
    );
  };
  
  return Wrapper;
};