import React, { FunctionComponent, ReactElement } from "react";

import { RequiresAuthentication } from "./RequiresAuthentication";

export const Page: FunctionComponent<{ children: ReactElement<any, any>, className?: string }> = ({ children, className = '' }) => {
    return (
        <RequiresAuthentication>
            <div className={`${className}`}>
                {children}
            </div>
        </RequiresAuthentication>
    );
}