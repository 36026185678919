import React from 'react';
import { CommandResponse } from '../../api';
import { Box, Button } from '@mui/material';
import { Select } from '../common/Select';
import { CreatorModeOption } from '../../api/creator-modes.types';
import { getCreatorModeItemOptions, loadCreatorMode, resetCreatorMode } from '../../api/creator-modes';
import { ConfirmationDialog } from '../common/Modal';

interface CreatorModesProps {}

interface CreatorModesState {
  selectedCreatorMode?: CreatorModeOption | null;
  creatorModeOptions: CreatorModeOption[];
  showResetConfirmation: boolean;
  showLoadConfirmation: boolean;
}

export class CreatorModes extends React.Component<CreatorModesProps, CreatorModesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      creatorModeOptions: [],
      selectedCreatorMode: null,
      showResetConfirmation: false,
      showLoadConfirmation: false,
    };
  }

  loadCreatorModes = async () => {
    const creatorModeOptions = await getCreatorModeItemOptions();

    this.setState({ creatorModeOptions });
  }

  componentDidMount(): void {
    this.loadCreatorModes();
  }

  onCreatorModeSelected = (item: CreatorModeOption) => {
    this.setState({ selectedCreatorMode: item });
    this.setState({ showLoadConfirmation: true });
  }

  onReset = async () => {
    this.setState({ showResetConfirmation: true });
  }

  onCloseResetConfirmation = () => {
    this.setState({ showResetConfirmation: false });
  }

  onCloseLoadConfirmation = () => {
    this.setState({ showLoadConfirmation: false, selectedCreatorMode: null });
  }

  onConfirmedReset = async () => {
    this.onCloseResetConfirmation();
    await resetCreatorMode();
  }

  onConfirmedLoad = async () => {
    this.onCloseLoadConfirmation();
    const { selectedCreatorMode } = this.state;
    if (!selectedCreatorMode?.item) {
      return;
    }

    await loadCreatorMode(selectedCreatorMode.item);
  }

  render = () => {
    const { creatorModeOptions, selectedCreatorMode, showResetConfirmation, showLoadConfirmation  } = this.state;

    return (
      <Box sx={{ typography: 'body1', mt: 3, fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <ConfirmationDialog
          id="reset-creator-mode"
          title="Reset Creator Mode"
          visible={showResetConfirmation}
          okButtonText='Reset'
          onConfirmed={this.onConfirmedReset}
          onClose={this.onCloseResetConfirmation}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to reset the creator mode?
            </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="load-creator-mode"
          title="Load Creator Mode"
          visible={showLoadConfirmation && !!selectedCreatorMode?.item}
          okButtonText='Load'
          onConfirmed={this.onConfirmedLoad}
          onClose={this.onCloseLoadConfirmation}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to load {selectedCreatorMode?.item?.name}?
            </Box>
        </ConfirmationDialog>
        <Box sx={{ typography: 'h6' }}>Creator Modes:</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <Select sx={{ width: '20rem' }} label={'Load Creator Mode'} value={selectedCreatorMode} items={creatorModeOptions} onItemSelected={this.onCreatorModeSelected}/>
          <Button sx={{ marginLeft: '1rem', padding: '1rem' }} onClick={this.onReset}>Reset</Button>
        </Box>
      </Box>
    );
  };
}
