import { combineReducers } from "redux";
import { PlayersReducer, PlayersState } from "./PlayersReducer";
import { SettingsReducer, SettingsState } from "./SettingsReducer";
import { AuthReducer, AuthState } from "./AuthReducer";

export interface ReduxState {
    players: PlayersState;
    settings: SettingsState;
    auth: AuthState;
}

export type GetStateFunction = () => ReduxState;
export type DispatchFunction = (action: { type: string, payload: any }) => void;

export const reducers: any = combineReducers({
    players: PlayersReducer,
    settings: SettingsReducer,
    auth: AuthReducer,
});