import React from 'react';
import { CommandResponse, sendCommand } from '../../api';
import { Box, TextField, Button } from '@mui/material';

interface CustomCommandProps {}

interface CustomCommandState {
  command: string;
  response?: CommandResponse;
}

export class CustomCommand extends React.Component<CustomCommandProps, CustomCommandState> {
  constructor(props: any) {
    super(props);
    this.state = {
      command: '',
    };
  }

  onSendCommand = async () => {
    const { command } = this.state;
    const response = await sendCommand(command);
    this.setState({ command: '', response });
  };

  onSetCommand = (e: any) => {
    this.setState({ command: e.target.value });
  };

  render = () => {
    const { command } = this.state;

    return (
      <Box sx={{ typography: 'body1', mt: 3, fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <Box sx={{ typography: 'h6' }}>Raw Command:</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
          <TextField
            type="text"
            name="command"
            value={command}
            onChange={this.onSetCommand}
            variant="outlined"
            size="medium"
            fullWidth
            sx={{ flex: 1, backgroundColor: '#1E1E1E', color: '#FFF' }}
            InputProps={{ sx: { color: '#00FF00' }, startAdornment: <Box component="span" sx={{ mr: 1, color: '#00FF00' }}>$</Box> }}
          />
          <Button variant="contained" color="primary" onClick={this.onSendCommand}>
            Send
          </Button>
        </Box>
        {this.state.response && (
          <Box
            sx={{
              mt: 2,
              backgroundColor: '#1E1E1E',
              color: '#FFF',
              p: 2,
              overflowY: 'auto',
              border: '1px solid #888',
              whiteSpace: 'pre-line',
            }}
          >
            <Box component="span" sx={{ color: '#00FF00' }}>$</Box> {this.state.response.response || 'No response'}
          </Box>
        )}
      </Box>
    );
  };
}
