import axios from 'axios';
import { CreatorModeItem, CreatorModeOption } from './creator-modes.types';
import { ServerSettings } from './settings.types';


const SETTINGS_ENDPOINT = '/settings'
const LOCATIONS_ENDPOINT = `${SETTINGS_ENDPOINT}/locations`
const RESPAWN_POINT_ENDPOINT = `${SETTINGS_ENDPOINT}/respawn-point`
const RESPAWN_GROWTH_ENDPOINT = `${SETTINGS_ENDPOINT}/respawn-growth`

export const getServerSettings = async(): Promise<ServerSettings> => {
    try {
        const { data } = await axios.get(SETTINGS_ENDPOINT);
        const { item } = data;
    
        return item as ServerSettings;
    } catch (e) {
        console.error(e);
    }

    return {} as ServerSettings;
}

export const loadServerLocations = async(): Promise<string[]> => {
    try {
        const {data} = await axios.get(LOCATIONS_ENDPOINT);
        const {items} = data;

        return items;
    } catch (e) {
        console.error(e);
    }

    return []
}

export const updateRespawnLocation = async(location: string): Promise<void> => {
    try {
        await axios.post(RESPAWN_POINT_ENDPOINT, {location});
    } catch (e) {
        console.error(e);
    }
}

export const updateRespawnGrowth = async(growth: string): Promise<void> => {
    try {
       await axios.post(RESPAWN_GROWTH_ENDPOINT, {growth});
    } catch (e) {
        console.error(e);
    }
}
