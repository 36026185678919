import React, { FunctionComponent } from 'react';
import { Box, ListItem, ListItemButton } from '@mui/material';

export interface SideMenuItemChildProps<T = any> {
    item?: T;
    children: React.ReactNode;
    onClick?: (item?: T) => any;
    href?: string;
}

export const SideMenuItemChild: FunctionComponent<SideMenuItemChildProps> = ({ children, href, item, onClick: propsOnClick }) => {
    const onClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (propsOnClick) {
            propsOnClick(item);
        }
    }, [propsOnClick, item]);

    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={onClick}
                sx={{
                    py: 2.5,
                    pl: 6,
                    pr: 3,
                    height: 24,
                    textOverflow: 'ellipsis',
                    color: 'grey.700',
                    '&:hover': {
                        color: 'blue.600',
                        backgroundColor: 'blue.50',
                    },
                    transition: 'all 0.3s ease-in-out',
                }}
            >
                {children}
            </ListItemButton>
        </ListItem>
    );
};
