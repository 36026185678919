import React from 'react';

export interface User {
  email: string;
  id: number;
  agid: string;
  avatar: string;
  avatarAccent: string;
  displayName: string;
  admin: boolean;
}

export interface AuthenticationParams {
  user?: User;
  accessToken?: string;
}

export interface AuthContextProps extends AuthenticationParams {
  authenticated: boolean
  loading: boolean
  initialized: boolean

  setAuthentication: (authenticationParams: AuthenticationParams) => void
  login: (username: string, password: string) => void
  logout: () => void
}

export const AuthContext =
  React.createContext<AuthContextProps | null>(null);
