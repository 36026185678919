import { red } from '@mui/material/colors';
import { Theme as MuiTheme, createTheme } from '@mui/material/styles';

// A custom theme for this app
const options = {
  palette: {
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
        },
        body: {
        },
      }
    }
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 450,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1750,
      xxxl: 2084,
    },
  },
}

export type Theme = (typeof options & MuiTheme) | MuiTheme

export default createTheme(options as Theme); 