import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Divider, Popover, Typography } from '@mui/material';

import { useAuth } from '../../common/hooks/useAuth';

export interface DropdownItemProps {
  onClick: (...args: any) => any
  children: React.ReactNode
}


export const DropdownItem: FunctionComponent<DropdownItemProps> = ({ children, onClick }) => {
  return (
      <Box sx={{ mx: 3 }}>
          <Button
              sx={{
                  textAlign: 'left',
                  py: 2,
                  fontSize: '0.75rem',
                  backgroundColor: 'transparent',
                  width: '100%',
                  transition: 'all 0.15s linear',
                  '&:focus': { outline: 'none' }
              }}
              onClick={onClick}
          >
              <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                {children}
              </Box>
          </Button>
      </Box>
  );
};

export interface DropdownProps {
  children?: any
}

export const Dropdown: React.FunctionComponent<DropdownProps> = ({children}): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </Popover>
  );
};

export const UserDropdown: React.FunctionComponent = (): JSX.Element | null => {
  const { user, logout } = useAuth();
  const { avatar, avatarAccent, displayName, agid } = user || {};
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPlayers = () => {
    navigate('/');
    handleClose();
  };

  const onCommand = () => {
    navigate('/command');
    handleClose();
  };

  const onSettings = () => {
    navigate('/settings');
    handleClose();
  };

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ paddingRight: '1rem', paddingTop: '.5rem', paddingBottom: '.5rem' }}>
      <Avatar
        src={avatar}
        alt="user avatar"
        sx={{
          width: 32,
          height: 32,
          backgroundColor: avatarAccent,
          border: '2px solid gray.600',
          cursor: 'pointer'
        }}
        onClick={handleClick}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: 224, py: 1, }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', pr: 3, pt: 2, pb: 2 }}>
            <Typography variant="body2" fontWeight="bold" color="blue.600">{displayName}</Typography>
            <Typography variant="body2" fontWeight="bold" color="blue.600">{agid}</Typography>
          </Box>
            <DropdownItem onClick={onPlayers}>Home</DropdownItem>
            {user?.admin && <DropdownItem onClick={onCommand}>Raw Command</DropdownItem>}
            {user?.admin && <DropdownItem onClick={onSettings}>Server Settings</DropdownItem>}
            <Divider sx={{ mx: 3 }} />
            <DropdownItem onClick={logout}>Logout</DropdownItem>
        </Box>
      </Popover>
    </Box>
  );
};
