import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faTicketAlt, faMap, faList, faCopy, faHandsHelping, faChartLine, faNewspaper, faRefresh } from '@fortawesome/free-solid-svg-icons'


import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { listPlayers, PlayerDetails } from '../../api';
import { Page } from '../common/Page';
import {PlayersTable} from '../players/PlayersTable';
// import { CustomCommand } from './CustomCommand';
import { WithMediaQueryProps, withMediaQuery } from '../../common/hooks/withMedia';
import { WithSmallScreenCheckProps, withSmallScreenCheck } from '../../common/hooks/withSmallScreenCheck';
import { CustomCommand } from '../command/CustomCommand';
import { PlayerCards } from '../players/PlayerCards';

const POLL_INTERVAL_MS = 1000 * 5;

interface HomeProps extends withAuthProps, WithSmallScreenCheckProps {}

interface HomeState {
  players: PlayerDetails[]
}

class HomeComponent extends React.Component<HomeProps, HomeState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      players: [],
    }
  }

  componentDidMount(): void {
    this.startPlayersPolling();
  }

  componentWillUnmount(): void {
      this.claerPlayersPolling();
  }

  startPlayersPolling = async() => {
    this.playerPollingId = setInterval(this.loadPlayers, POLL_INTERVAL_MS);
    await this.loadPlayers()
  }

  claerPlayersPolling = () => {
    clearInterval(this.playerPollingId);
  }

  loadPlayers = async() => {
    const { authenticated } = this.props
    if(!authenticated) {
      return
    }

    const players = await listPlayers();
    const sortedPlayers = [...players].sort((a, b) => {
      if (a.agid === this.props.user?.agid) {
        return -1;
      }
      if (b.agid === this.props.user?.agid) {
        return -1;
      }
      return 1
    });

    this.setState({ players: sortedPlayers })
  }


  render = () => {
    const { isSmallScreen } = this.props;
    const { players } = this.state;


    return (
        <Page>
            <Box sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                    <Typography variant="h6">Active Players (#{players?.length})</Typography>
                    <Button sx={{ ml: 2 }} onClick={this.loadPlayers}><FontAwesomeIcon icon={faRefresh} /></Button>
                </Box>
                {isSmallScreen && <PlayerCards players={players} />}
                {!isSmallScreen && <PlayersTable players={players} />}
            </Box>
        </Page>
    );
  }
}


export const Home = withAuth(withSmallScreenCheck(withAuth(HomeComponent)))