import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { withSmallScreenCheck } from '../../common/hooks/withSmallScreenCheck';
import { Page } from '../common/Page';
import { CreatorModes } from './CreatorModes';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import { loadServerSettings } from '../../actions/settings';
import { RespawnLocation } from './RespawnLocation';
import { RespawnGrowth } from './RespawnGrowth';

interface ReduxStateProps {
}

interface ReduxActionProps {
  loadServerSettings: () => void;
  loadServerLocations: () => void;
}

interface ComponentProps extends withAuthProps {}

interface SettingsState {
}

export type SettingsProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class SettingsComponent extends React.Component<SettingsProps, SettingsState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      players: [],
    }
  }

  componentDidMount(): void {
    this.props.loadServerSettings()
    
  }

  render = () => {

    return (
        <Page>
            <Box sx={{ p: 5 }}>
               <CreatorModes />
                <Box sx={{ typography: 'h6', mt: '1rem' }}>Respawn Settings:</Box>
               <RespawnLocation />
               <RespawnGrowth />
            </Box>
        </Page>
    );
  }
}


const mapStateToProps = ({  }: ReduxState) => ({});

export const SettingsPage =  withSmallScreenCheck(connect(mapStateToProps, {loadServerSettings})(SettingsComponent));